import { Order, PickingStatus, TaskState } from '@quickcommerceltd/zappboard'
import { OrderOrigin } from '@quickcommerceltd/zappboard/lib/types/order-origin'
import { collection, orderBy, query, where } from 'firebase/firestore'
import { DateTime } from 'luxon'
import { useMemo } from 'react'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { db, orderTimestampConverter } from '../../firebase'

export const useStoreViewDataDeliveroo = (storeId: string) => {
  const now = DateTime.now()
  const last3Hours = useMemo(() => now.minus({ hours: 3 }).toJSDate(), [now.hour])

  return useCollectionData<Order>(
    query(
      collection(db, `warehouseStats/${storeId}/orders`),
      where('deliveryStatus', 'in', [TaskState.PENDING, TaskState.UNASSIGNED, TaskState.ASSIGNED]),
      where('orderOrigin', '==', OrderOrigin.DELIVEROO),
      where('pickingStatus', '==', PickingStatus.PACKED),
      where('createdAt', '>', last3Hours),
      orderBy('createdAt', 'desc')
    ).withConverter(orderTimestampConverter)
  )
}
